import { socioGrpcClient } from "@/setup/socioGrpcClient";

// == ============ ==
// ** Document API **
// == ============ ==

/**
 *
 * @param {*} gedProjectId
 * @param {*} metadata
 * @returns
 */
export const getDooAppReport = async (gedProjectId, metadata = {}) => {
    const request = new socioGrpcClient.atm_back.atm.DocumentRequest();
    request.setGedProjectId(gedProjectId)
    const response = await socioGrpcClient.atm_back.atm.DocumentControllerPromiseClient.getReportByProjectId(
      request,
      metadata
    )
    return response.toObject();
};