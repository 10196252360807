import { AosVuexOrmModel } from "@socotec.io/socio-aos-component";
import { BaseAssetModel } from "./baseAssetModel";

export default class ZoneAsset extends BaseAssetModel {
  static entity = "zoneAssets";
  static aosModel = () => AosVuexOrmModel["AosZone"];

  static fields() {
    return {
      ...super.fields(),

      // Global fields
      livingArea: this.number(0),

      // Permea Fields
      rtCirculationStatus: this.boolean(false),
      roofTopOnOutside: this.boolean(false),
      roofTopOnAnotherZone: this.boolean(false),
      rtInsideDim: this.boolean(false),
      interiorVolumeUncertainty: this.string(""),
      permeaCalculationLinesVerticalResult: this.number(0),
      permeaCalculationLinesHorizontalResult: this.number(0),
      permeaCalculationLinesVolumeResult: this.number(0),
      atbatResult: this.number(0),

      // DPE Fields
      wallLength: this.attr(0),
      throughHousing: this.boolean(0),
      ceilingHeight: this.attr(0),
      areaLowFloor: this.attr(0),
      areaUpperFloor: this.attr(0),
      areaWindowsNorth: this.attr(0),
      areaWindowsEast: this.attr(0),
      areaWindowsWest: this.attr(0),
      areaWindowsSouth: this.attr(0),
      areaOpaqueWalls: this.attr(0),

      // Ventilation
      ventilationControlStatus: this.boolean(true),
      ventilationHomeType: this.attr(""),
      boxIdentification: this.attr(),
    };
  }
}
