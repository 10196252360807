import { socioGrpcClient } from "@/setup/socioGrpcClient";

/**
 *
 * @param {aosBuildingUuid} aosBuildingUuid - AssetBuilding uuid
 * @param {*} metadata
 * @returns
 */
export const retrieveBuildingWithLocations = async (
  aosBuildingUuid,
  metadata = {},
) => {
  const request = new socioGrpcClient.aos_back.structure.BuildingRetrieveRequest();
  request.setUuid(aosBuildingUuid);
  const response = await socioGrpcClient.aos_back.structure.BuildingControllerPromiseClient.retrieveWithLocations(
    request,
    metadata
  );
  return response.toObject();
};

/**
 *
 * @param {aosBuildingUuid} aosBuildingUuid - AssetBuilding uuid
 * @param {*} metadata
 * @returns
 */
export const retrieveBuildingAos = async (
  aosBuildingUuid,
  metadata = {},
) => {
  const request = new socioGrpcClient.aos_back.structure.BuildingRetrieveRequest();
  request.setUuid(aosBuildingUuid);
  const response = await socioGrpcClient.aos_back.structure.BuildingControllerPromiseClient.retrieve(
    request,
    metadata
  );
  return response.toObject();
};

/**
 *
 * @param {object} metadata - metadata containing filters
 * @return {object}
 */
export const fetchZonesFromAos = async (metadata = {}) => {
  const requestAos = new socioGrpcClient.aos_back.structure.ZoneListRequest();
  const responseAos = await socioGrpcClient.aos_back.structure.ZoneControllerPromiseClient.list(
    requestAos,
    metadata
  );
  return responseAos.toObject().resultsList;
};