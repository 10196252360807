import casesChildren from "@/router/childrens/atm-front/cases-childrens";

const Pilotage = () =>
  import(/* webpackChunkName: "Grpc" */ "@/components/atm-front/Pilotage");

const Cases = () =>
  import(/* webpackChunkName: "Rest" */ "@/components/atm-front/Cases");


const MyProfile = () =>
  import(/* webpackChunkName: "Rest" */ "@/components/atm-front/MyProfile");

export default [
  {
    path: "",
    name: "home",
    redirect: { name: "casesList" }
  },
  {
    path: "cases",
    component: Cases,
    children: casesChildren
  },
  {
    path: "pilot",
    name: "pilotage",
    component: Pilotage
  },
  {
    path: "profile",
    name: "edit-profile", // INFO - MS - route name comes from SocioMainNavbarUserMenu (socio-vue-components)
    component: MyProfile,
  },
];
