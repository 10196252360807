import { User as BaseUser } from "@socotec.io/socio-vue-components";

export default class User extends BaseUser {
  static entity = "users";

  static fields() {
    return {
      ...super.fields(),
      certificationNumber: this.string("")
    };
  }

  isPrivileged() {
    // Add permissions here if needed for aos
    return true;
  }
}
