import i18n from "@/setup/i18n";
import { useSubNavigations } from "@/composables/navigations/useSubCaseNavigations";

const { getNavItems } = useSubNavigations(i18n);

const allowIfNavItemNotDisabled = async (to, from, next) => {
    const items = await getNavItems()
    const item = items.value.find(navItem => navItem.routeName === to.name);

    if(item?.disabled) {
        next({path: 'cases'})
    } else {
        next()
    }
}

export default allowIfNavItemNotDisabled