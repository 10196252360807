import * as atmBackApi from "@/api/atm-back";
import GeneralSettings from "@/models/GeneralSettings";

const state = {};

const getters = {
    getGeneralSettingsByCaseAtmUuid: () => (caseAtmUuid) => {
        return GeneralSettings
            .query()
            .with('atmCase')
            .where('caseInfo', caseAtmUuid)
            .first() // to avoid return an array of only 1 object
    }
};

const actions = {
    async fetchGeneralSettings(context, currentCaseAtmUuid) {
        const metadata = {
            filters: JSON.stringify({
                case_info : currentCaseAtmUuid
            })
        }
        const response = await atmBackApi.fetchGeneralSettings(metadata)
        if (response.count !== 0) {
            await GeneralSettings.create({data: response.resultsList})
        }
    },
    
    async updateOrCreateGeneralSettings(context, [generalSettingsData, currentCase]) {
        if (getters.getGeneralSettingsByCaseAtmUuid()(currentCase.atmUuid) !== null) {
            const response = await atmBackApi.updateGeneralSettings(generalSettingsData, currentCase)
            await GeneralSettings.create({
                data: response
            })
        } else {
            const response = await atmBackApi.createGeneralSettings(generalSettingsData, currentCase)
            await GeneralSettings.create({data: response})
        }
    },
};

const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}