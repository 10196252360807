import { socioGrpcClient } from "@/setup/socioGrpcClient";
const google_protobuf_struct_pb = require("google-protobuf/google/protobuf/struct_pb.js");

export const retrieveLastReportForObjectId = async (
  objectId,
  metadata = {},
  failIfNoFound = true
) => {
  try {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.report_generator.reports
        .ReportGetLastReportForObjectIdRequest,
      { objectId }
    );
    const response = await socioGrpcClient.report_generator.reports.ReportControllerPromiseClient.getLastReportForObjectId(
      request,
      metadata
    );
    return response.toObject();
  } catch (error) {
    if (error.code === 5 && !failIfNoFound) {
      return undefined;
    }
    throw error;
  }
};

export const fetchMailList = async (metadata) => {
  const request = new socioGrpcClient.report_generator.mails.MailListRequest();
  let response = await socioGrpcClient.report_generator.mails.MailControllerPromiseClient.list(
    request,
    {
      ...metadata
    }
  );
  return response.toObject();
}

export const createMailType = async (data, metadata = {}) => {
  const request = socioGrpcClient.javascriptToRequest(
    socioGrpcClient.report_generator.mails.MailTypeRequest,
    data
  );
  const response = await socioGrpcClient.report_generator.mails.MailTypeControllerPromiseClient.create(
    request,
    metadata
  );
  return response.toObject();
}

export const fetchMailTypeList = async (metadata = {}) => {
  const request = new socioGrpcClient.report_generator.mails.MailTypeListRequest();
  const response = await socioGrpcClient.report_generator.mails.MailTypeControllerPromiseClient.list(
    request,
    metadata
  );
  return response.toObject();
}

export const createMailDocument = (
  data,
  generationData,
  metadata = {},
) => {
  const mailGenerationData = new google_protobuf_struct_pb.Struct.fromJavaScript(
    JSON.parse(JSON.stringify(generationData))
  );

  const request = socioGrpcClient.javascriptToRequest(
    socioGrpcClient.report_generator.mails.MailCreationRequest,
    data
  );

  request.setMailGenerationData(mailGenerationData);
  const stream = socioGrpcClient.report_generator.mails.MailControllerPromiseClient.create(
    request,
    metadata
  );
  return stream;
};

export const fetchReportTemplateList = async () => {
  const metadata = {
    filters: JSON.stringify({
      service_id: process.env.VUE_APP_ATM_SERVICE_ID,
    }),
  };
  const request =
    new socioGrpcClient.report_generator.reports.ReportTemplateListRequest();
  const response =
    await socioGrpcClient.report_generator.reports.ReportTemplateControllerPromiseClient.list(
      request,
      metadata
    );
  return response.toObject()
}

export default {
  retrieveLastReportForObjectId,
  fetchMailList,
  createMailType,
  fetchMailTypeList,
  createMailDocument,
  fetchReportTemplateList
}
