import { socioGrpcClient } from "@/setup/socioGrpcClient";
import google_protobuf_empty_pb from "google-protobuf/google/protobuf/empty_pb.js";
import { camelToSnakeCase } from "@/utils/helpers";
const excludedKeys = ["createdAt", "updatedAt"];

// == ============ ==
// ** CaseInfo API **
// == ============ ==

/**
 *
 * @param {*} caseNumber
 * @param {*} metadata
 * @param {*} failIfNoFound
 * @returns
 */
export const resolveUser = async (
  metadata = {}
) => {
  const response =
    await socioGrpcClient.atm_back.user.UserControllerPromiseClient.resolveUser(
      new google_protobuf_empty_pb.Empty(),
      metadata
    );

  return await response.toObject();
};


/**
 *
 * @param {*} userData
 * @param {*} metadata
 * @returns
 */
export const updateUser = async (
  userData,
  metadata = {}
) => {
  const request = socioGrpcClient.javascriptToRequest(
    socioGrpcClient.atm_back.user
      .UserPartialUpdateRequest,
    {
      ...userData
    }
  );
  request.setPartialUpdateFieldsList(
    Object.keys(userData).filter(key => !excludedKeys.includes(key)).map(key => {
      const toSnake = camelToSnakeCase(key);
      return toSnake.endsWith("_list") ? toSnake.replace("_list", "") : toSnake;
    })
  );
  const response = await socioGrpcClient.atm_back.user.UserControllerPromiseClient.partialUpdate(
    request,
    metadata
  );
  return response.toObject();
};
