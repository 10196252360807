import { utils } from "@socotec.io/socio-aos-component";
import { BaseModel } from "./baseModels";
import AtmCase from "./AtmCase";

export default class CaseObservable extends BaseModel {
  static entity = "CaseObservables";

  static fields() {
    return {
      ...super.fields(),
      assetType: this.string(""),
      assetModel: this.attr(null),
      assetData: this.morphTo("assetUuid", "assetModel"),
      atmCaseData: this.belongsTo(AtmCase, "atmCase"),

      // Foreign keys
      assetUuid: this.string(""),
      aosObservable: this.string(""), // not used for now
      aosItem: this.string(""),
      atmCase: this.string("")
    };
  }

  async fetchAosItem() {
    return await utils.aosConst.AOS_ITEMS[this.assetType.toLowerCase()]
      .aosModelClass()
      .query()
      .getFromStoreOrFetchOne(this.aosItem);
  }

  get aosItemData() {
    return utils.aosConst.AOS_ITEMS[this.assetType.toLowerCase()]
      .aosModelClass()
      .find(this.aosItem);
  }
}
