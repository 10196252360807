import { socioGrpcClient } from "@/setup/socioGrpcClient";
import { camelToSnakeCase } from "@/utils/helpers";

const excludedKeys = ["createdAt", "updatedAt"];
/**
 *
 * @param {object} metadata - metadata containing filters & pagination parameters
 * @return {object} All aos objects linked to this case info.
 */
export const fetchCaseAssets = async metadata => {
  const request = new socioGrpcClient.atm_back.atm.CaseInfoAosObservableListRequest();
  const response = await socioGrpcClient.atm_back.atm.CaseInfoAosObservableControllerPromiseClient.utils.listAllObjects(
    request,
    metadata
  );
  return await response;
};

/**
 *
 * @param {string} CaseInfoObservableUuid - Uuid of the CaseInfoAosObservable
 * @param {object} metadata
 * @returns {object} The unique aos asset found with the given uuid.
 */
export const retrieveCaseAsset = async (
  CaseInfoObservableUuid,
  metadata = {}
) => {
  const request = new socioGrpcClient.atm_back.atm.CaseInfoAosObservableRetrieveRequest();
  request.setUuid(CaseInfoObservableUuid);

  const response = await socioGrpcClient.atm_back.atm.CaseInfoAosObservableControllerPromiseClient.retrieve(
    request,
    metadata
  );
  return response.toObject();
};

/**
 *
 * @param {object} formattedAssetsData - Object containing an asset type (building...) as key and a list as value
 * @param {string} caseInfoUuid - Uuid of the linked case info
 * @param {object} metadata
 * @returns {object} A list of created elements.
 */
export const addCaseAssets = async (
  formattedAssetsData,
  caseInfoUuid,
  metadata = {}
) => {

  const request = new socioGrpcClient.atm_back.atm.AddCaseInfoAssetsRequest();
  // map subassets data to gRPC request
  ["storeys", "zones", "spaces"].forEach(assetType => {
    formattedAssetsData[assetType] = formattedAssetsData[assetType].map(
      item => {
        const request = assetType === "zones" ? new socioGrpcClient.atm_back.atm.BuildingZoneAssetRequest() : new socioGrpcClient.atm_back.atm.BuildingAssetRequest();
        request.setAosItem(item.aosItem);
        request.setPath(item.path);
        if (assetType === "zones") {
          request.setLivingArea(item.livingArea);
        }
        return request;
      }
    );
  });
  request.setCaseInfoUuid(caseInfoUuid);
  request.setSitesList(formattedAssetsData.sites);
  request.setBuildingsList(formattedAssetsData.buildings);
  request.setStoreysList(formattedAssetsData.storeys);
  request.setZonesList(formattedAssetsData.zones);
  request.setSpacesList(formattedAssetsData.spaces);

  const response = await socioGrpcClient.atm_back.atm.CaseInfoAosObservableControllerPromiseClient.addCaseInfoAssets(
    request,
    metadata
  );
  return response.toObject().resultsList;
};

/**
 * Removes a list of assets attached to a CaseInfo
 *
 * @param {object} selection - An object with structures as key and array of AOS items uuids as value
 * {
 *  site: [aos_uuid1, aos_uuid2],
 *  building: [aos_uuid3],
 *  storey: [storey_uuid4]
 * }
 * @param {string} caseInfoUuid - Uuid of the linked case info
 * @param {object} metadata
 */
export const removeCaseAssetsInAtm = async (
  selection,
  caseInfoUuid,
  metadata = {}
) => {
  const request = new socioGrpcClient.atm_back.atm.RemoveCaseInfoAssetsRequest();
  request.setCaseInfoUuid(caseInfoUuid);
  request.setSitesList(selection.site ?? []);
  request.setBuildingsList(selection.building ?? []);
  request.setStoreysList(selection.storey ?? []);
  request.setZonesList(selection.zone ?? []);
  request.setSpacesList(selection.space ?? []);

  return await socioGrpcClient.atm_back.atm.CaseInfoAosObservableControllerPromiseClient.removeCaseInfoAssets(
    request,
    metadata
  );
};

/**
 * @param {object} bulkCreateData
 * @param {object} metadata
 */
export const bulkCreateBuildings = async (bulkCreateData, metadata = {}) => {
  const buildingsRequest = bulkCreateData.map(buildingAssetData =>
    socioGrpcClient.javascriptToRequest(
      socioGrpcClient.atm_back.aos.BuildingRequest,
      {
        site: buildingAssetData.site,
        aosItem: buildingAssetData.aosItem, 
        path: buildingAssetData.path
      }
    )
  );
  const request = new socioGrpcClient.atm_back.aos.BuildingBulkCreateRequest();
  request.setBuildingsList(buildingsRequest);
  const response = await socioGrpcClient.atm_back.aos.BuildingControllerPromiseClient.bulkCreate(
    request,
    metadata
  );
  return response.toObject().resultsList;
};

/**
 *
 * @param {array} aosSites - List of sites uuids from aos microservice
 * @param {boolean} withBuildings - Adds building to the response or not
 * @param {object} metadata - metadata containing filters
 * @return {object} All aos objects linked to this case info.
 */
export const fetchSites = async (aosSites, metadata = {}) => {
  const request = new socioGrpcClient.atm_back.aos.SiteListRequest();
  request.setAosItemsList(aosSites);
  const response = await socioGrpcClient.atm_back.aos.SiteControllerPromiseClient.list(
    request,
    metadata
  );
  return response.toObject().resultsList;
};

export const fetchBuildings = async (metadata = {}) => {
  const request = new socioGrpcClient.atm_back.aos.BuildingListRequest();
  const response =
    await socioGrpcClient.atm_back.aos.BuildingControllerPromiseClient.list(
      request,
      metadata
    );
  return response.toObject().resultsList;
};

export const siteInfoByCase = async (requestData, metadata = {}) => {
  const request = socioGrpcClient.javascriptToRequest(
    socioGrpcClient.atm_back.aos.SiteSiteInfoByCaseRequest,
    requestData
  );

  const response = await socioGrpcClient.atm_back.aos.SiteControllerPromiseClient.siteInfoByCase(
    request,
    metadata
  );

  return response.toObject();
}

export const buildingInfoByCase = async (requestData, metadata = {}) => {
  const request = socioGrpcClient.javascriptToRequest(
    socioGrpcClient.atm_back.aos.BuildingBuildingInfoByCaseRequest,
    requestData
  );

  const response = await socioGrpcClient.atm_back.aos.BuildingControllerPromiseClient.buildingInfoByCase(
    request,
    metadata
  );

  return response.toObject();
}

/**
 * @param {object} bulkCreateData - Data containing sites to create
 * @param {object} metadata
 */
export const bulkCreateSites = async (bulkCreateData, metadata = {}) => {
  const sitesRequest = bulkCreateData.map(siteAssetData => {
    return socioGrpcClient.javascriptToRequest(
      socioGrpcClient.atm_back.aos.SiteRequest,
      {
        case: siteAssetData.caseInfo,
        aosItem: siteAssetData.aosItem,
        path: siteAssetData.path
      }
    );
  });
  const request = new socioGrpcClient.atm_back.aos.SiteBulkCreateRequest();
  request.setSitesList(sitesRequest);
  const response = await socioGrpcClient.atm_back.aos.SiteControllerPromiseClient.bulkCreate(
    request,
    metadata
  );
  return response.toObject().resultsList;
};

/**
 *
 * @param {uuid} assetBuildingUuid - AssetBuilding uuid
 * @param {object} metadata - metadata containing filters
 * @return {object}
 */
export const retrieveBuilding = async (assetBuildingUuid, metadata = {}) => {
  const request = new socioGrpcClient.atm_back.aos.BuildingRetrieveRequest();
  request.setUuid(assetBuildingUuid);
  const response = await socioGrpcClient.atm_back.aos.BuildingControllerPromiseClient.retrieve(
    request,
    metadata
  );
  return response.toObject();
};

/**
 *
 * @param {*} assetBuildingData
 * @param {*} metadata
 * @returns
 */
export const updateBuilding = async (assetBuildingData, metadata = {}) => {
  const request = socioGrpcClient.javascriptToRequest(
    socioGrpcClient.atm_back.aos.BuildingPartialUpdateRequest,
    {
      ...assetBuildingData
    }
  );
  console.log(assetBuildingData);
  request.setPartialUpdateFieldsList(
    Object.keys(assetBuildingData).filter(key => !excludedKeys.includes(key)).map(key => {
      const toSnake = camelToSnakeCase(key);
      return toSnake.endsWith("_list") ? toSnake.replace("_list", "") : toSnake;
    })
  );
  const response = await socioGrpcClient.atm_back.aos.BuildingControllerPromiseClient.partialUpdate(
    request,
    metadata
  );
  return response.toObject();
};

/**
 *
 * @param {object} metadata - metadata containing filters
 * @return {object}
 */
export const fetchZones = async (metadata = {}) => {
  const request = new socioGrpcClient.atm_back.aos.ZoneListRequest();
  const response = await socioGrpcClient.atm_back.aos.ZoneControllerPromiseClient.list(
    request,
    metadata
  );
  return response.toObject().resultsList;
};

/**
 *
 * @param {uuid} assetZoneUuid - AssetZone uuid
 * @param {object} metadata - metadata containing filters
 * @return {object}
 */
export const retrieveZone = async (assetZoneUuid, metadata = {}) => {
  const request = new socioGrpcClient.atm_back.aos.ZoneRetrieveRequest();
  request.setUuid(assetZoneUuid);
  const response = await socioGrpcClient.atm_back.aos.ZoneControllerPromiseClient.retrieve(
    request,
    metadata
  );
  return response.toObject();
};

/**
 *
 * @param {*} assetZoneData
 * @param {*} metadata
 * @returns
 */
export const updateZone = async (assetZoneData, metadata = {}) => {
  const request = socioGrpcClient.javascriptToRequest(
    socioGrpcClient.atm_back.aos.ZonePartialUpdateRequest,
    {
      ...assetZoneData
    }
  );
  request.setPartialUpdateFieldsList(
    Object.keys(assetZoneData).filter(key => !excludedKeys.includes(key)).map(key => {
      const toSnake = camelToSnakeCase(key);

      return toSnake.endsWith("_list") ? toSnake.replace("_list", "") : toSnake;
    })
  );
  const response = await socioGrpcClient.atm_back.aos.ZoneControllerPromiseClient.partialUpdate(
    request,
    metadata
  );
  return response.toObject();
};


/**
 *
 * @param {*} uuid
 * @param {*} calculationType
 * @param {*} metadata
 * @returns
 */
export const duplicateZones = async (
  sourceZone,
  targetZones,
  fieldsToDuplicate,
  metadata = {}
) => {
  const request = socioGrpcClient.javascriptToRequest(
    socioGrpcClient.atm_back.aos.DuplicateZonesRequest,
    {
      sourceZone,
      targetZones,
      fieldsToDuplicate: fieldsToDuplicate.map(key => camelToSnakeCase(key))
    }
  );

  await socioGrpcClient.atm_back.aos.ZoneControllerPromiseClient.duplicateZones(
    request,
    metadata
  );
  return;
};
