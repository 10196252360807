import axios from "axios";

let testRestClient = axios.create({
  baseURL: process.env.VUE_APP_BACK_URL,
  headers: {}
});

const initTestRestAuth = accessToken => {
  testRestClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

export default testRestClient;
export { testRestClient, initTestRestAuth };
