import { socioGrpcClient } from "@/setup/socioGrpcClient";

// == =========== ==
// == Mission API ==
// == =========== ==

/**
 *
 * @param {*} metadata
 * @returns
 */
export const fetchMissions = async (metadata = {}) => {
  const request = new socioGrpcClient.atm_back.atm.MissionListRequest();
  const response = await socioGrpcClient.atm_back.atm.MissionControllerPromiseClient.list(
    request,
    metadata
  );
  return response.toObject();
};

/**
 *
 * @param {*} metadata
 * @returns
 */
export const updateMission = async (missionData, metadata = {}) => {
  const request = new socioGrpcClient.atm_back.atm.MissionRequest();
  request.setUuid(missionData.uuid);
  request.setComment(missionData.comment);
  request.setDateRdv(missionData.dateRdv);
  request.setReviewContract(missionData.reviewContract);
  request.setPhase(missionData.phase);

  const response = await socioGrpcClient.atm_back.atm.MissionControllerPromiseClient.update(
    request,
    metadata
  );
  return response.toObject();
};
