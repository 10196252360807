import { socioGrpcClient } from "@/setup/socioGrpcClient";
import { camelToSnakeCase } from "@/utils/helpers";
const excludedKeys = ["createdAt", "updatedAt"];

// == ========== ==
// == PERMEA API ==
// == ========== ==

/**
 *
 * @param {*} metadata
 * @returns
 */
export const fetchDpeWindowsAreaCalculationLines = async (metadata = {}) => {
  const request = socioGrpcClient.javascriptToRequest(
    socioGrpcClient.atm_back.atm.DpeWindowsAreaCalculationLineListRequest,
    {}
  );
  const response = await socioGrpcClient.atm_back.atm.DpeWindowsAreaCalculationLineControllerPromiseClient.list(
    request,
    metadata
  );
  return response.toObject();
};

/**
 *
 * @param {*} dpeCalculationLineData
 * @param {*} metadata
 * @returns
 */
export const createDpeCalculationLine = async (
  dpeCalculationLineData,
  metadata = {}
) => {
  const request = socioGrpcClient.javascriptToRequest(
    socioGrpcClient.atm_back.atm.DpeWindowsAreaCalculationLineRequest,
    {
      ...dpeCalculationLineData
    }
  );
  const response = await socioGrpcClient.atm_back.atm.DpeWindowsAreaCalculationLineControllerPromiseClient.create(
    request,
    metadata
  );
  return response.toObject();
};

/**
 *
 * @param {*} uuid
 * @param {*} metadata
 * @returns
 */
export const destroyDpeWindowsAreaCalculationLine = async (
  uuid,
  metadata = {}
) => {
  const request = socioGrpcClient.javascriptToRequest(
    socioGrpcClient.atm_back.atm.DpeWindowsAreaCalculationLineDestroyRequest,
    {
      uuid
    }
  );
  const response = await socioGrpcClient.atm_back.atm.DpeWindowsAreaCalculationLineControllerPromiseClient.destroy(
    request,
    metadata
  );
  return response.toObject();
};

/**
 *
 * @param {*} dpeWindowsAreaCalculationLineData
 * @param {*} metadata
 * @returns
 */
export const updateDpeWindowsAreaCalculationLine = async (
  dpeWindowsAreaCalculationLineData,
  metadata = {}
) => {
  const request = socioGrpcClient.javascriptToRequest(
    socioGrpcClient.atm_back.atm
      .DpeWindowsAreaCalculationLinePartialUpdateRequest,
    {
      ...dpeWindowsAreaCalculationLineData
    }
  );
  request.setPartialUpdateFieldsList(
    Object.keys(dpeWindowsAreaCalculationLineData).filter(key => !excludedKeys.includes(key)).map(key => {
      const toSnake = camelToSnakeCase(key);
      return toSnake.endsWith("_list") ? toSnake.replace("_list", "") : toSnake;
    })
  );
  const response = await socioGrpcClient.atm_back.atm.DpeWindowsAreaCalculationLineControllerPromiseClient.partialUpdate(
    request,
    metadata
  );
  return response.toObject();
};

export const createDpeCsv = async (
  requestData,
  metadata = {}
) => {
  const request = socioGrpcClient.javascriptToRequest(
    socioGrpcClient.atm_back.atm
      .DpeWindowsAreaCalculationLineExportCSVRequest,
      requestData
  );
  const response = await socioGrpcClient.atm_back.atm.DpeWindowsAreaCalculationLineControllerPromiseClient.exportCSV(
    request,
    metadata
  );
  return response.toObject();
};
