import Vue from "vue";
import Vuex from "vuex";
import VuexORM from "@vuex-orm/core";

// Vuex OIDC
import { vuexOidcCreateStoreModule } from "vuex-oidc";
import { oidcSettings } from "@/setup/OIDCSettings";

// Client
import { initTestRestAuth } from "@/setup/axios";
import socioGrpcClient, { initAtmAuth } from "@/setup/socioGrpcClient";

import {
  Company,
  Client,
  Agency,
  AgencyGroup,
  Case,
  DocumentType,
  DocumentStatus,
  ReportTemplate,
} from "@socotec.io/socio-vue-components";

import AtmCase from "@/models/AtmCase";
import Mission from "../models/Mission";
import PermeaCalculationLine from "@/models/PermeaCalculationLine";
import PermeaTag from "@/models/PermeaTag";
import DpeWindowsAreaCalculationLine from "@/models/DpeWindowsAreaCalculationLine";
import Transmission from "@/models/Transmission";
import GeneralSettings from "@/models/GeneralSettings";
import Portion from "@/models/Portion";

import CaseModule from "./modules/case";
import UserModule from "./modules/user";
import MissionModule from "./modules/mission";
import SiteModule from "./modules/site";
import SpaceModule from "./modules/space";
import AssetModule from "./modules/asset";
import BuildingModule from "./modules/building";
import ZoneModule from "./modules/zone";
import StoreyModule from "./modules/storey";
import PermeaModule from "./modules/permea";
import DpeModule from "./modules/dpe";
import VentilationModule from "./modules/ventilation";
import TransmissionModule from "./modules/transmission";
import ReportModule from "./modules/report";
import GeneralSettingsModule from "./modules/generalSettings";
import PortionModule from "./modules/portion";

import { notifications as NotificationModule } from "@socotec.io/socio-vue-components";
import DocumentModule from "./modules/document";
import AtmCaseProjectManager from "@/models/AtmCaseProjectManager";
import User from "@/models/User";
import SiteAsset from "@/models/SiteAsset";
import ZoneAsset from "@/models/ZoneAsset";
import StoreyAsset from "@/models/StoreyAsset";
import BuildingAsset from "@/models/BuildingAsset";
import SpaceAsset from "@/models/SpaceAsset";
import CaseObservable from "@/models/CaseObservable";
import Document from "@/models/Document";
import Report from "@/models/Report";

Vue.use(Vuex);

const database = new VuexORM.Database();

database.register(User);
database.register(Agency);
database.register(AgencyGroup);
database.register(Company);
database.register(Case);
database.register(AtmCase);
database.register(Mission);
database.register(Client);
database.register(Document);
database.register(AtmCaseProjectManager);
database.register(SiteAsset);
database.register(CaseObservable);
database.register(BuildingAsset);
database.register(StoreyAsset);
database.register(SpaceAsset);
database.register(ZoneAsset);
database.register(PermeaCalculationLine);
database.register(PermeaTag);
database.register(DpeWindowsAreaCalculationLine);
database.register(Transmission);
database.register(DocumentType);
database.register(DocumentStatus);
database.register(GeneralSettings)
database.register(ReportTemplate)
database.register(Report)
database.register(Portion);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    oidc: vuexOidcCreateStoreModule(
      oidcSettings,
      {
        namespaced: true,
        dispatchEventsOnWindow: true,
      },
      {
        userLoaded: (user) => {
          initTestRestAuth(user.access_token);
          initAtmAuth(user.access_token);
          socioGrpcClient.setAccessToken(user.access_token);
        },
      }
    ),
    notifications: NotificationModule,
    cases: CaseModule,
    user: UserModule,
    missions: MissionModule,
    document: DocumentModule,
    asset: AssetModule,
    site: SiteModule,
    building: BuildingModule,
    zone: ZoneModule,
    space: SpaceModule,
    storey: StoreyModule,
    permea: PermeaModule,
    dpe: DpeModule,
    ventilation: VentilationModule,
    transmission: TransmissionModule,
    report: ReportModule,
    generalSettings: GeneralSettingsModule,
    portions: PortionModule
  },
  plugins: [VuexORM.install(database)],
});
