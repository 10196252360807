import { socioGrpcClient } from "@/setup/socioGrpcClient";

/**
 *
 * @param {*} metadata
 * @returns
 */
export const fetchTransmissions = async (metadata = {}) => {
  const request = socioGrpcClient.javascriptToRequest(
    socioGrpcClient.atm_back.transmission.TransmissionListRequest,
    {}
  );
  const response = await socioGrpcClient.atm_back.transmission.TransmissionControllerPromiseClient.list(
    request,
    metadata
  );
  return response.toObject();
};


/**
 *
 * @param {*} buildingData
 * @param {*} userUuid
 * @param {*} caseInfoUuid
 * @param {*} metadata
 * @returns
 */
export const createTransmission = async (
  type,
  buildingData,
  userUuid,
  caseInfoUuid,
  metadata = {}
) => {
  const request = new socioGrpcClient.atm_back.transmission.TransmissionRequest();
  request.setCaseInfo(caseInfoUuid);
  request.setUserUuid(userUuid);
  request.setType(type);
  buildingData.forEach(
    data => {
      request.addBuildingData(
        socioGrpcClient.javascriptToRequest(
          socioGrpcClient.atm_back.transmission
            .TransmissionBuildingDataRequest, data
        )
      )
    }
  );

  const response = await socioGrpcClient.atm_back.transmission.TransmissionControllerPromiseClient.create(
    request,
    metadata
  );

  return response.toObject();
};


