const ReportList = () =>
  import(
    /* webpackChunkName: "ReportList" */ "@/components/atm-front/cases/case/Reports/ReportList"
  );
const AcousticMeasuresStepper = () =>
  import(
    /* webpackChunkName: "AcousticMeasuresStepper" */ "@/components/atm-front/cases/case/Reports/AcousticMeasuresStepper"
  );

export default [
  {
    path: "",
    name: "ReportList",
    component: ReportList
  },
  {
    path: "acoustic-measures/:reportTemplateUuid",
    name: "AcousticMeasuresStepper",
    component: AcousticMeasuresStepper
  }
];
