import AtmCase from "@/models/AtmCase.js";
import { Client, User } from "@socotec.io/socio-vue-components";
import * as atmBackApi from "../../api/atm-back";
import * as atlasBackApi from "../../api/atlas-back";
import * as userManagementBackApi from "../../api/user-management-back";
import _ from "lodash"
import router from '@/router'

const state = {
  currentCaseNumber: null,
  fetchingStatus: false,
  totalCaseCount: 0
};

const getters = {
  getCurrentCase: state => {
    return AtmCase.query()
      .with("client")
      .with("manager")
      .with("contributors")
      .find(state.currentCaseNumber);
  },
  getAllCases: () => {
    return AtmCase.query()
      .with("client")
      .with("manager")
      .with("contributors")
      .get();
  },
  getCasesTotalCount: state => {
    return state.totalCaseCount;
  },
  getCasesFetchingStatus: state => {
    return state.fetchingStatus;
  }
};

const actions = {
  /**
   * Fetch Case list
   * @param [_]
   * @param metadata
   * @returns {Promise<AtmCase[]>}
   */
  async fetchCases(context, queryAsObject) {
    context.commit("SET_FETCHING_STATUS", true);

    // Clear
    Client.deleteAll();
    AtmCase.deleteAll();

    const metadata = {
      filters: JSON.stringify({
        ...queryAsObject,
      })
    };

    let response
    try {
      response = await atmBackApi.fetchCases(metadata)
    } catch (e) {
      context.commit("SET_TOTAL_CASE_COUNT", 0);
      context.commit("SET_FETCHING_STATUS", false);
      router.push("/")
      return
    }


    const userMapping = {}
    const userUuids = _.uniq(_.flatten(
      response.resultsList.map((case_) => case_.missionsList.map(
        (mission) => mission.projectManagerUsermanagementUuid )
      )
    ))
    if (userUuids.length) {
      const contributorsList = await userManagementBackApi.fetchUsers(userUuids)
      contributorsList.map((contributor) => {
        userMapping[contributor.uuid] = contributor
      })
    }

    response.resultsList.map((caseInfo) => {
      User.insertOrUpdate({
        data: {
          email: caseInfo.managerEmail,
          firstName: caseInfo.managerFirstName,
          lastName: caseInfo.managerLastName,
          uuid: caseInfo.managerUsermanagementUuid,
        }
      })
      caseInfo.contributors = caseInfo.missionsList.map(
        (mission) => userMapping[mission.projectManagerUsermanagementUuid]
      )
      AtmCase.insertFromAtmBack(caseInfo);
    })
    context.commit("SET_TOTAL_CASE_COUNT", response.count);
    context.commit("SET_FETCHING_STATUS", false);

    return response.count;
  },

  /**
   * Retrieve Case
   * @param [_]
   * @param caseNumber
   * @returns {Promise<AtmCase>}
   */
  async retrieveCaseByCaseNumber(context, caseNumber) {
    context.commit("SET_FETCHING_STATUS", true);
    // Fetch Atlas cases
    const atlasCase = await atlasBackApi.retrieveCaseByCaseNumber(caseNumber);
    const atmCaseInfo = await atmBackApi.retrieveCaseInfoByCaseNumber(caseNumber);
    const userUuids = atmCaseInfo.missionsList.map(
      (mission) => mission.projectManagerUsermanagementUuid
    )

    const contributorsList = await userManagementBackApi.fetchUsers(userUuids)
    contributorsList.map((contributor) => {
      User.insertOrUpdate({data: contributor})
    })
    const atlasClient = atlasBackApi.retrieveClient(atlasCase.client)

    User.insertOrUpdate({
      data: {
        email: atmCaseInfo.managerEmail,
        firstName: atmCaseInfo.managerFirstName,
        lastName: atmCaseInfo.managerLastName,
        uuid: atmCaseInfo.managerUsermanagementUuid,
      }
    })

    // Insert Client and User
    Client.insertOrUpdate({
      data: atlasClient
    });

    AtmCase.createFromMultipleSource(
      atlasCase,
      atmCaseInfo,
      contributorsList
    );

    await context.commit("SET_CURRENT_CASE_NUMBER", caseNumber);
    context.commit("SET_FETCHING_STATUS", false);
  },

  async synchroniseCurrentCaseMissions(context, force = true) {
    context.commit("SET_FETCHING_STATUS", true);
    await context.dispatch("_synchroniseCurrentCaseMissions", force);
    context.commit("SET_FETCHING_STATUS", false);
  },

  async _synchroniseCurrentCaseMissions(context, force = false) {
    atmBackApi.synchroniseCaseMissions(context.getters.getCurrentCase, force);
  },

  /**
   * Update Atm CaseInfo data
   * @param [_]
   * @param caseNumber
   * @param caseData
   * @returns {Promise<AtmCase>}
   */
  async updateATMCase(context, caseData) {
    context.commit("SET_FETCHING_STATUS", true);
    const response = await atmBackApi.updateCaseInfo(caseData);
    await AtmCase.updateAtmData(caseData.caseNumber, response);
    context.commit("SET_FETCHING_STATUS", false);
  },

  /**
   * Update Atm Contact data
   * @param [_]
   * @param Objects
   * @returns {Promise<AtmCase>}
   */
  async updateATMCaseContact(_, { caseNumber, contactField, contactData }) {
    const response = await atmBackApi.updateContact(contactData);
    response;
    return await AtmCase.update({
      where: caseNumber,
      data: {
        [contactField]: response
      }
    });
  },
};

const mutations = {
  SET_CURRENT_CASE_NUMBER: (state, caseNumber) => {
    state.currentCaseNumber = caseNumber;
  },
  SET_TOTAL_CASE_COUNT: (state, totalCaseCount) => {
    state.totalCaseCount = totalCaseCount;
  },
  SET_FETCHING_STATUS: (state, status) => {
    state.fetchingStatus = status;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
