const CaseIdentityCardDefault = () =>
  import(
    /* webpackChunkName: "CaseIdentityCardDefault" */ "@/components/atm-front/cases/case/CaseIdentityCard/CaseIdentityCardDefault"
  );
const CaseIdentityCardRelation = () =>
  import(
    /* webpackChunkName: "CaseIdentityCardRelation" */ "@/components/atm-front/cases/case/CaseIdentityCard/CaseIdentityCardRelation"
  );
const CaseIdentityCardMission = () =>
  import(
    /* webpackChunkName: "CaseIdentityCardRelation" */ "@/components/atm-front/cases/case/CaseIdentityCard/CaseIdentityCardMission"
  );

export default [
  {
    path: "",
    name: "CaseIdentityCardDefault",
    component: CaseIdentityCardDefault
  },
  {
    path: "relation",
    name: "CaseIdentityCardRelation",
    component: CaseIdentityCardRelation
  },
  {
    path: "mission",
    name: "CaseIdentityCardMission",
    component: CaseIdentityCardMission
  }
];
