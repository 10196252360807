import { Document as BaseDocument } from "@socotec.io/socio-vue-components";

export default class Document extends BaseDocument {
    static entity = "document";
    static primaryKey = "uuid";


  static fields() {
    return {
      ...super.fields(),
      file: this.attr({}),
    }
  }
}