const Informations = () =>
  import(
    /* webpackChunkName: "Informations" */ "@/components/atm-front/cases/case/Acoustic/Informations"
  );
const Portions = () =>
  import(
    /* webpackChunkName: "Portions" */ "@/components/atm-front/cases/case/Acoustic/Portions"
    );
    
    const PortionDetail = () =>
    import(
      /* webpackChunkName: "PortionDetail" */ "@/components/atm-front/cases/case/Acoustic/PortionDetail"
    )

export default [
  {
    path: "",
    name: "Informations",
    component: Informations
  },
  {
    path: "portions",
    name: "Portions",
    component: Portions
  },
  {
    path: "portions/:portionUuid",
    name: "PortionDetail",
    component: PortionDetail
  }
];
