import Transmission from "@/models/Transmission";
import * as atmBackApi from "../../api/atm-back";

const getters = {
  getTransmissions() {
    return Transmission.all();
  }
};

const actions = {
  fetchTransmissions: async (_, { caseInfoUuid = null, type = null, status = null, metadata = null } = {}) => {
    const fullMetadata = {
      filters: JSON.stringify({
        case_info: caseInfoUuid,
        type,
        status,
        ...metadata
      })
    }
    const response = await atmBackApi.fetchTransmissions(fullMetadata);
    await Transmission.insertOrUpdate({
      data: response.resultsList
    });
    return response;
  },
  createTransmission: async (_, { buildingData, caseInfo, user, type, metadata }) => {
    const response = await atmBackApi.createTransmission(type, buildingData, user.uuid, caseInfo.atmUuid, metadata);
    await Transmission.insertOrUpdate({
      data: [response]
    });
    return response;
  }
};

export default {
  namespaced: true,
  actions,
  getters
};
