import { Model } from "@vuex-orm/core";

export default class PermeaTag extends Model {
  static entity = "PermeaTag";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.uid(),
      permeaLabel: this.attr(0),
      order: this.attr(0),
      calculationType: this.attr(""),
    };
  }
}
