import { socioGrpcClient } from "@/setup/socioGrpcClient";

// == =========== ==
// == Contact API ==
// == =========== ==

/**
 *
 * @param {*} contactData
 * @param {*} metadata
 * @returns
 */
export const updateContact = async (contactData, metadata = {}) => {
  const request = new socioGrpcClient.atm_back.atm.ContactRequest();
  request.setUuid(contactData.uuid);
  request.setCompany(contactData.company);
  request.setRole(contactData.role);
  request.setAddress(contactData.address);
  request.setFirstName(contactData.firstName);
  request.setLastName(contactData.lastName);
  request.setPostcode(contactData.postcode);
  request.setCity(contactData.city);
  request.setMail(contactData.mail);
  request.setTelephone(contactData.telephone);
  const response = await socioGrpcClient.atm_back.atm.ContactControllerPromiseClient.update(
    request,
    metadata
  );
  return response.toObject();
};
