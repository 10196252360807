import Vue from "vue";
import VueRouter from "vue-router";

import AtmFrontChildrens from "@/router/childrens/atm-front-children";

import store from "@/store";
import { vuexOidcCreateRouterMiddleware } from "vuex-oidc";

const OidcCallback = () =>
  import(/* webpackChunkName: "OidcCallback" */ "@/components/OidcCallback");
const AtmFront = () =>
  import(/* webpackChunkName: "AtmFront" */ "@/components/AtmFront");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: AtmFront,
    children: AtmFrontChildrens
  },
  {
    path: "/oidc-callback", // Needs to match redirect_uri in you oidcSettings
    name: "oidcCallback",
    component: OidcCallback,
    meta: {
      isVuexOidcCallback: true,
      isPublic: true
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store, "oidc"));

export default router;
