import * as atmBackApi from "@/api/atm-back";
import User from "@/models/User";

import Mission from "@/models/Mission";
import { ATM_MISSION_CODES } from "../../constants/atm";
import * as userManagementBackApi from "../../api/user-management-back";

const state = {};

const getters = {
  getAllMissions: () => {
    return Mission.query()
      .with("projectManager")
      .get();
  }
};

const actions = {
  async fetchMissions(_, queryAsObject) {
    const metadata = {
      filters: JSON.stringify({
        ...queryAsObject,
        offer_item_code: ATM_MISSION_CODES.toString(),
        offer_item_status: [6, 12].toString()
      })
    };

    const atmMissionResponse = await atmBackApi.fetchMissions(metadata);

    if (atmMissionResponse.projectManagerUsermanagementUuid) {
      const caseUserManager = await userManagementBackApi.retrieveByUuid(
        atmMissionResponse.projectManagerUsermanagementUuid,
        {},
        false
      );
      if (caseUserManager) {
        User.insertOrUpdate({
          data: caseUserManager
        });
      }
    }
    Mission.deleteAll();
    await Mission.insert({
      data: atmMissionResponse.resultsList
    });
  },

  async updateMission(_, missionData) {
    const response = await atmBackApi.updateMission(missionData);
    await Mission.update({
      where: missionData.uuid,
      data: response
    });
  }
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
