import DpeWindowsAreaCalculationLine from "@/models/DpeWindowsAreaCalculationLine";
import {
  fetchDpeWindowsAreaCalculationLines,
  createDpeCalculationLine,
  updateDpeWindowsAreaCalculationLine,
  destroyDpeWindowsAreaCalculationLine
} from "../../api/atm-back";

const state = {};

const getters = {
  getAllDpeWindowsAreaCalculationLines: () => {
    return DpeWindowsAreaCalculationLine.all();
  }
};

const actions = {
  async fetchDpeWindowsAreaCalculationLines(context, assetZoneUuid) {
    const metadata = {
      filters: JSON.stringify({
        zone: assetZoneUuid
      })
    };
    const response = await fetchDpeWindowsAreaCalculationLines(metadata);

    await DpeWindowsAreaCalculationLine.deleteAll();
    await DpeWindowsAreaCalculationLine.insert({
      data: response.resultsList
    });
  },
  async synchronizedDpeWindowsAreaCalculationLines(
    context,
    { newDataSet, oldDataSet }
  ) {
    const datasetToCreate = newDataSet.filter(data => Boolean(data.uuid));
    const datasetToUpdate = newDataSet.filter(data => !data.uuid);
    const datasetToUpdateUuids = datasetToUpdate.map(data => data.uuid);
    const dataToRemove = oldDataSet.filter(
      data => !datasetToUpdateUuids.includes(data.uuid)
    );

    datasetToCreate.map(data =>
      context.dispatch("createDpeWindowsAreaCalculationLine", data)
    );
    datasetToUpdate.map(data =>
      context.dispatch("updateDpeWindowsAreaCalculationLine", data)
    );
    dataToRemove.map(data =>
      context.dispatch("deleteDpeWindowsAreaCalculationLine", data.uuid)
    );
  },
  async createDpeWindowsAreaCalculationLine(
    _,
    dpeWindowsAreaCalculationLineData
  ) {
    const response = await createDpeCalculationLine(
      dpeWindowsAreaCalculationLineData
    );
    await DpeWindowsAreaCalculationLine.insertOrUpdate({
      data: [response]
    });
  },
  async deleteDpeWindowsAreaCalculationLine(
    _,
    dpeWindowsAreaCalculationLineUuid
  ) {
    await destroyDpeWindowsAreaCalculationLine(
      dpeWindowsAreaCalculationLineUuid
    );
    await DpeWindowsAreaCalculationLine.delete(
      dpeWindowsAreaCalculationLineUuid
    );
  },
  async updateDpeWindowsAreaCalculationLine(
    _,
    dpeWindowsAreaCalculationLineData
  ) {
    const response = await updateDpeWindowsAreaCalculationLine(
      dpeWindowsAreaCalculationLineData
    );
    await DpeWindowsAreaCalculationLine.insertOrUpdate({
      data: [response]
    });
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
