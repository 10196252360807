import { socioGrpcClient } from "@/setup/socioGrpcClient";

export const fetchCases = async (metadata = {}) => {
  const request = socioGrpcClient.javascriptToRequest(
    socioGrpcClient.atlas_as_a_service.atlas.CaseListRequest,
    {}
  );
  const response = await socioGrpcClient.atlas_as_a_service.atlas.CaseControllerPromiseClient.listWithProjectManagerUsermanagementUuids(
    request,
    metadata
  );
  return response.toObject();
};

export const retrieveCaseByCaseNumber = async (
  caseNumber,
  metadata = {},
  failIfNoFound = true
) => {
  try {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.atlas_as_a_service.atlas.RetrieveCaseByCaseNumberMessage,
      { caseNumber }
    );
    const response = await socioGrpcClient.atlas_as_a_service.atlas.CaseControllerPromiseClient.retrieveCaseByCaseNumber(
      request,
      metadata
    );
    return response.toObject();
  } catch (error) {
    if (error.code === 5 && !failIfNoFound) {
      return undefined;
    }
    throw error;
  }
};

export const retrieveClient = async (
  uuid,
  metadata = {},
  failIfNoFound = true
) => {
  try {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.atlas_as_a_service.atlas.ClientRetrieveRequest,
      { uuid: uuid }
    );
    const response = await socioGrpcClient.atlas_as_a_service.atlas.ClientControllerPromiseClient.retrieve(
      request,
      metadata
    );
    return response.toObject();
  } catch (error) {
    if (error.code === 5 && !failIfNoFound) {
      return undefined;
    }
    throw error;
  }
};
