import { BaseModel } from "./baseModels";

export class BaseAssetModel extends BaseModel {
  static aosModel = () => null;

  static fields() {
    return {
      isCaseAsset: this.boolean(),
      aosItem: this.string(""), // foreign key
      aosItemData: this.belongsTo(this.aosModel(), "aosItem"),
      path: this.string(""),
      ...super.fields()
    };
  }

  toAos() {
    return this.constructor.aosModel().find(this.aosItem);
  }
}
