import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./setup/i18n";
import vuetify from "./setup/vuetify";
import SocioVueComponents from "@socotec.io/socio-vue-components";
import AosComponents from "@socotec.io/socio-aos-component";
import { socioGrpcClient } from "@/setup/socioGrpcClient";

Vue.config.productionTip = false;

Vue.use(SocioVueComponents);
store.$client = socioGrpcClient;
Vue.use(AosComponents, { store });

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
