import { AosVuexOrmModel } from "@socotec.io/socio-aos-component";
import { BaseAssetModel } from "./baseAssetModel";

export default class SiteAsset extends BaseAssetModel {
  static entity = "siteAssets";
  static aosModel = () => AosVuexOrmModel["AosSite"];
  static listAction = "site/fetchSites";

  static fields() {
    return {
      ...super.fields(),
      isCaseAsset: this.boolean(),
    };
  }
}
