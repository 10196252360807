import {
  BILLED_OFFER_ITEM_CODES,
  CANCELLED_OFFER_ITEM_CODES,
  TERMINATED_OFFER_ITEM_CODES,
  TO_BILL_OFFER_ITEM_CODES
} from "@/constants/atm";

export const convertOfferItemStatus = statusCode => {
  if (TO_BILL_OFFER_ITEM_CODES.includes(statusCode)) return "toBill";
  if (BILLED_OFFER_ITEM_CODES.includes(statusCode)) return "billed";
  if (TERMINATED_OFFER_ITEM_CODES.includes(statusCode)) return "terminated";
  if (CANCELLED_OFFER_ITEM_CODES.includes(statusCode)) return "cancelled";
  return "unknown";
};

export const camelToSnakeCase = str =>
  str.replace(/[A-Z0-9]/g, (ltr, idx) =>
    idx > 0 ? `_${ltr.toLowerCase()}` : ltr.toLowerCase()
  );

export const objectFieldsCamelToSnakeCase = object => {
  return Object.fromEntries(
    Object.entries(object).map(([key, value]) => [
      camelToSnakeCase(key),
      value
    ])
  );
}

export const stripHTMLTags = htmlString => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  return doc.body.textContent || "";
}

export const sortingZoneSpaceRules = (a, b) => {
  const typologyAsc = a.aosItemData.typologyData?.label.localeCompare(
    b.aosItemData.typologyData?.label
  );

  const siteLabelAsc = a.aosItemData.siteLabel.localeCompare(
    b.aosItemData.siteLabel
  );

  const buildingLabelAsc = a.aosItemData.buildingLabel.localeCompare(
    b.aosItemData.buildingLabel
  );

  const labelAsc = a.aosItemData.label.localeCompare(b.aosItemData.label);

  return typologyAsc || siteLabelAsc || buildingLabelAsc || labelAsc;
};

export const formatFilters = filters => {
  return JSON.stringify(
    Object.fromEntries(
      Object.entries(filters).map(([key, value]) => [
        camelToSnakeCase(key),
        value
      ])
    )
  );
};

export const filterDocuments = (value) => {
  return value.split(".").pop() === "xml" 
    || value.split(".").pop() === "pdf" 
    || value.split(".").pop() === "png" 
    || value.split(".").pop() === "jpg";
};

export const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

export const downloadFile = (url, filename) => {
  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const blobURL = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = blobURL;

      a.setAttribute('download', filename);
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(blobURL);
      document.body.removeChild(a);
    })
    .catch(error => console.error(error));
};