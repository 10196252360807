import { ReportTemplate } from "@socotec.io/socio-vue-components";
import reportApi from "@/api/report-generator-back";
const google_protobuf_struct_pb = require("google-protobuf/google/protobuf/struct_pb.js");
import { socioGrpcClient } from "@/setup/socioGrpcClient";
import { streamStatuses } from "@/utils/const";
import { setRequestFilters } from "@/utils/request";
import i18n from "@/setup/i18n";
import Report from "@/models/Report";

const {
  atm_back: { report_generator: reportGeneratorApi },
} = socioGrpcClient;

const state = {
  reportsCount: 0,
  generatingReport: false,
};

const getters = {
  getReportsCount: (state) => {
    return state.reportsCount;
  },
  isReportGenerating: (state) => {
    return state.generatingReport;
  },
  getTemplateByUuid: () => (uuid) => {
    return ReportTemplate.query().where("uuid", uuid).first();
  },
};

const actions = {
  async fetchReportTemplates() {
    const responseObjects = await reportApi.fetchReportTemplateList();
    return await ReportTemplate.insertOrUpdate({
      data: responseObjects.resultsList,
    });
  },
  async generateReport(
    { dispatch, commit },
    { reportTemplateUuid, reportGenerationData, metadata = {} }
  ) {
    const generationData = {
      reportTemplateUuid,
      reportGenerationData: new google_protobuf_struct_pb.Struct.fromJavaScript(
        reportGenerationData
      ),
    };
    const request = socioGrpcClient.javascriptToRequest(
      reportGeneratorApi.GenerateReportRequest,
      generationData,
      []
    );
    commit("SET_GENERATING_REPORT", true);

    const stream =
      await reportGeneratorApi.ReportGeneratorControllerPromiseClient.generate(
        request,
        metadata
      );

    stream.on("data", async function (response) {
      let action = {
        [streamStatuses.SUCCESS]: async () => {
          dispatch(
            "notifications/showSuccessNotification",
            i18n.t("reportMessages.generationSuccess"),
            { root: true }
          );
          dispatch("retrieveReport", response.getReportUuid());
        },
        [streamStatuses.GENERATION_FAILED]: async () => {
          dispatch(
            "notifications/showErrorNotification",
            i18n.t("reportMessages.generationError"),
            { root: true }
          );
        },
        [streamStatuses.UPLOAD_FAILED]: async () => {
          dispatch(
            "notifications/showErrorNotification",
            i18n.t("reportMessages.generationError"),
            { root: true }
          );
        },
        [streamStatuses.ERROR]: async () => {
          dispatch(
            "notifications/showErrorNotification",
            i18n.t("reportMessages.generationError"),
            { root: true }
          );
        },
        [streamStatuses.GENERATING]: async () => {},
      };

      await action[response.getCode()]();
      if (response.getCode() !== streamStatuses.GENERATING) {
        commit("SET_GENERATING_REPORT", false);
      }
    });
  },

  async listReports({commit}, { filters = {}, pagination = {} }) {
    const metadata = {
      pagination: JSON.stringify(pagination),
    };

    const request = setRequestFilters({
      request: new socioGrpcClient.report_generator.reports.ReportListRequest(),
      filters,
    });

    const response =
      await socioGrpcClient.report_generator.reports.ReportControllerPromiseClient.list(
        request,
        metadata
      );
    commit("UPDATE_REPORTS_COUNT", response.getCount());
    const responseObject = response.getResultsList();
    const reportsList = responseObject.map((element) => element.toObject());
    await Report.insert({
      data: reportsList,
    });
  },

  async retrieveReport({ getters, commit }, reportUuid) {
    const request = new socioGrpcClient.report_generator.reports.Report();

    request.setUuid(reportUuid);

    const response =
      await socioGrpcClient.report_generator.reports.ReportControllerPromiseClient.retrieve(
        request,
        {}
      );
    const reportData = response.toObject();

    reportData.reportGenerationData = response
      .getReportGenerationData()
      .toJavaScript();

    await Report.insertOrUpdate({
      data: reportData,
    });
    commit("UPDATE_REPORTS_COUNT", getters.getReportsCount + 1);
  },

  async deleteReport({commit, getters}, reportUuid) {
    const request =
      new socioGrpcClient.report_generator.reports.ReportDestroyRequest();
    request.setUuid(reportUuid);

    await socioGrpcClient.report_generator.reports.ReportControllerPromiseClient.destroy(
      request,
      {}
    );
    await Report.delete(reportUuid);
    commit("UPDATE_REPORTS_COUNT", getters.getReportsCount - 1);
  },
};

const mutations = {
  SET_GENERATING_REPORT: function (state, newValue) {
    state.generatingReport = newValue;
  },
  UPDATE_REPORTS_COUNT: function (state, newTotal) {
    state.reportsCount = newTotal;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
