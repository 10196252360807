const CasesList = () =>
  import(
    /* webpackChunkName: "CasesList" */ "@/components/atm-front/cases/CasesList"
  );
const Case = () =>
  import(/* webpackChunkName: "Case" */ "@/components/atm-front/cases/Case");

import CaseChildrens from "./case/case-children";
import store from "@/store";

const setCurrentCase = async (to, from, next) => {
  if (to.params.caseNumber !== from.params.caseNumber) {
    await store.dispatch(
      "cases/retrieveCaseByCaseNumber",
      to.params.caseNumber
    );
  }
  next();
};

export default [
  {
    path: "",
    name: "casesList",
    component: CasesList
  },
  {
    path: ":caseNumber",
    component: Case,
    children: CaseChildrens,
    beforeEnter: setCurrentCase
  }
];
