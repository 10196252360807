import { AosVuexOrmModel } from "@socotec.io/socio-aos-component";
import { BaseAssetModel } from "./baseAssetModel";

export default class BuildingAsset extends BaseAssetModel {
  static entity = "buildingAssets";
  static aosModel = () => AosVuexOrmModel["AosBuilding"];

  static fields() {
    return {
      ...super.fields(),
      isCaseAsset: this.boolean(),

      // Global fields
      buildingType: this.string(""),
      ceilingHeight: this.number(0),
      sampling: this.boolean(false),
      cmvType: this.string(""),
      cmvRegulationType: this.string(""),
      visitDate: this.string(""),

      // DPE fields
      heatingType: this.string(""),

      // Ventilation fields
      ventilationMissionTypesList: this.attr([]),
      ventilationReferencial: this.string(""),
      collectiveBoxNumber: this.number(0),

      // RE2020
      depositBuildingPermitDate: this.string(""),
      buildPermitDate: this.string(""),
      parcelCodes: this.attr([]), // INFO - Leo Hauchecorne - 29/06/2023 - This field is only filled when retrieveFullBuilding is called. Otherwise, it's an empty list (it's used only on some parts. To avoid unncessary traffic, it's not always retrieved.
    };
  }
}
