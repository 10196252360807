import CaseObservable from "@/models/CaseObservable";

export const formatAndInsertObservables = async observables => {
  await CaseObservable.insertOrUpdate({
    data: observables.map(buildObservableObj)
  });
};

const buildObservableObj = item => {
  return {
    ...item,
    assetModel: `${item.assetType.toLowerCase()}Assets`
  };
};
