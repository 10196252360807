import { AosVuexOrmModel } from "@socotec.io/socio-aos-component";
import SiteAsset from "@/models/SiteAsset";
import { bulkCreateSites, fetchSites } from "@/api/atm-back";

const getters = {
  getCaseSites() {
    return SiteAsset.query()
      .where("isCaseAsset", true)
      .with("aosItemData")
      .with("aosItemData.locations")
      .get();
  }
};

const actions = {
  fetchSites: async (_, { aosSites = [], metadata = {} }) => {
    const response = await fetchSites(aosSites, metadata);
    const data = response.map(s => ({ ...s }));
    await SiteAsset.insertOrUpdate({
      data
    });
    return await response;
  },
  bulkCreate: async (_, { data, insertData }) => {
    if (!data || !data.length) return [];
    data.forEach(siteAssetData => {
      AosVuexOrmModel["AosSite"].insertOrUpdate({
        data: { ...siteAssetData.aosItemData, isDisplayed: true }
      });
    });
    const resultsList = await bulkCreateSites(data);
    await SiteAsset.insertOrUpdate({
      data: resultsList.map(siteAsset => ({ ...siteAsset, ...insertData }))
    });
    return resultsList;
  }
};

export default {
  namespaced: true,
  actions,
  getters
};
