import { ASSET_ITEMS } from "@/utils/const";
import { capitalize } from "@/utils/helpers";
import { formatAndInsertObservables } from "@/utils/response";
import { useAosEventBus, utils } from "@socotec.io/socio-aos-component";
import SiteAsset from "@/models/SiteAsset";
import BuildingAsset from "@/models/BuildingAsset";
import CaseObservable from "@/models/CaseObservable";
import StoreyAsset from "@/models/StoreyAsset";
import ZoneAsset from "@/models/ZoneAsset";
import SpaceAsset from "@/models/SpaceAsset";
import {
  addCaseAssets,
  fetchCaseAssets,
  removeCaseAssetsInAtm,
  retrieveCaseAsset
} from "@/api/atm-back";


const state = {
  assetsLoaded: false,
  tagsSelected: []
};

const getters = {
  isAssetsLoaded: state => {
    return state.assetsLoaded;
  },
  retrieveAssetFromAosItem: () => (assetType, aosItemUuid) => {
    const { modelClass } = ASSET_ITEMS[assetType];
    return modelClass()
      .query()
      .where("aosItem", aosItemUuid)
      .first();
  },
  getAosItemsUuidsFromAssets: () => {
    let allAssetUuidsByStructure = {};
    Object.keys(ASSET_ITEMS).forEach(assetItemKey => {
      allAssetUuidsByStructure[assetItemKey] = ASSET_ITEMS[assetItemKey]
        .modelClass()
        .query()
        .where("isCaseAsset", true)
        .get()
        .map(asset => asset.aosItem);
    });
    return allAssetUuidsByStructure;
  }
};

const actions = {
  async fetchAssets({ commit, dispatch, getters }, reset = false) {
    if (getters.isAssetsLoaded & !!reset) {
      return;
    }
    await dispatch("fetchSiteAssets");
    await dispatch("fetchCaseAssets");
    // INFO - MS - 17/11/2O22 - can't use useAosEventBus because
    // AosPanel not mounted to listen on events when fetchAssets is called
    commit(
      "aos/SET_AOS_REQUEST_FILTER",
      { uuids: getters.getAosItemsUuidsFromAssets },
      {
        root: true
      }
    );
    // INFO - B.L - Do not remove or it breaks mapbox
    const sitesUuids = SiteAsset.query().where("isCaseAsset", true).all().map(({aosItem}) => aosItem)
    dispatch("aos/site/fetchSites", {requestData: {uuids: sitesUuids}}, {root: true})
    commit("SET_ASSETS_LOADED", true);
  },

  async fetchSiteAssets({ rootGetters, dispatch }) {
    const response = await dispatch(
      "site/fetchSites",
      {
        metadata: {
          filters: JSON.stringify({
            case: rootGetters["cases/getCurrentCase"].atmUuid
          })
        }
      },
      { root: true }
    );
    [SiteAsset, BuildingAsset].forEach(model => {
      model.update({
        where: asset => {
          return asset.isCaseAsset === true;
        },
        data: { isCaseAsset: false }
      });
    });
    let siteAssets = response.map(siteAsset => {
      const data = siteAsset;
      return {
        ...data,
        isCaseAsset: true
      };
    });
    await SiteAsset.insertOrUpdate({ data: siteAssets });
  },

  async fetchCaseAssets({ rootGetters, dispatch }) {
    const metadata = {
      filters: JSON.stringify({
        case_info: rootGetters["cases/getCurrentCase"].atmUuid
      }),
      pagination: JSON.stringify({
        page_size: 500
      })
    };
    const atmCaseAssets = await fetchCaseAssets(metadata);
    await dispatch("storeCaseInfoAssetsFromResponse", atmCaseAssets);
  },

  async retrieveCaseInfoAsset({ dispatch }, CaseInfoAosObservableUuid) {
    const caseAsset = await retrieveCaseAsset(CaseInfoAosObservableUuid);
    await dispatch("storeCaseInfoAssetsFromResponse", [caseAsset]);
  },

  async addCaseAssets({ dispatch, rootGetters }, assetsData) {
    let formattedAssetsData = {
      sites: assetsData.sites ?? [],
      buildings: assetsData.buildings ?? [],
      storeys: assetsData.storeys ?? [],
      zones: assetsData.zones ?? [],
      spaces: assetsData.spaces ?? []
    };
    const addedCaseAssets = await addCaseAssets(
      formattedAssetsData,
      rootGetters["cases/getCurrentCase"].atmUuid
    );
    await dispatch("storeCaseInfoAssetsFromResponse", addedCaseAssets);
  },

  async storeCaseInfoAssetsFromResponse(_, assetsResponse) {
    for (const assetType in ASSET_ITEMS) {
      const assetItems = assetsResponse.filter(
        asset => asset.assetType === capitalize(assetType)
      );
      const assetItemsUuids = assetItems.map(a => a.assetUuid);

      // reset isProjectAsset bool in case of concurrent modifications (could have changed)
      await ASSET_ITEMS[assetType].modelClass().update({
        where: asset => {
          return (
            asset.isCaseAsset === true && assetItemsUuids.includes(asset.uuid)
          );
        },
        data: { isCaseAsset: false }
      });
      await ASSET_ITEMS[assetType].modelClass().insertOrUpdate({
        data: assetItems.map(item => {
          return {
            uuid: item.assetUuid,
            aosItem: item.aosItem,
            isCaseAsset: true
          };
        })
      });
    }
    await formatAndInsertObservables(assetsResponse);
  },

  // Removes the {Model}Asset of each selected items in the navAmos, based on his aos item uuid
  async removeCaseInfoAssets({ rootState, rootGetters }, selection) {
    const breadcrumbEntries = Object.entries(
      rootGetters["aos/breadcrumbSelectionsAsUuids"]()
    );
    // Filter the breadcrumbSelections based on the structure checkbox selection made in the RemoveFromAmosModal
    const breadcrumbEntriesFiltered = breadcrumbEntries.filter(([key]) =>
      selection.includes(key)
    );
    const filteredBreadcrumbs = Object.fromEntries(breadcrumbEntriesFiltered);
    const response = await removeCaseAssetsInAtm(
      filteredBreadcrumbs,
      rootGetters["cases/getCurrentCase"].atmUuid
    );
    const itemsToRemove = response.toObject();
    const { aosEventBus } = useAosEventBus();

    for (const assetItemsKey in itemsToRemove) {
      const itemsToRemoveUuids = itemsToRemove[assetItemsKey];
      const moduleName = assetItemsKey.replace("sList", "");
      if (!ASSET_ITEMS[moduleName] || !itemsToRemoveUuids.length) {
        continue;
      }
      // Remove assets
      await Promise.all([
        ASSET_ITEMS[moduleName]
          .modelClass()
          .delete(asset => itemsToRemoveUuids.includes(asset.uuid)),
        CaseObservable.delete(caseObs =>
          itemsToRemoveUuids.includes(caseObs.assetUuid)
        )
      ]);
      // Update the count of the module and clean the selection
      const currentCount = rootState.aos[moduleName][`${moduleName}TotalCount`];
      const newTotalCount = currentCount - itemsToRemoveUuids.length;

      aosEventBus.$emit(
        utils.aosEventsConst.AOS_EVENTS_LISTENED[
          "UPDATE_STRUCTURE_TOTAL_ITEMS_COUNT"
        ].eventName,
        moduleName,
        newTotalCount < 0 ? 0 : newTotalCount
      );
    }
  },

  async resetState({ commit, dispatch }) {
    dispatch("aos/resetPanelState", null, { root: true });
    await Promise.all([
      SiteAsset.deleteAll(),
      BuildingAsset.deleteAll(),
      StoreyAsset.deleteAll(),
      ZoneAsset.deleteAll(),
      SpaceAsset.deleteAll(),
      CaseObservable.deleteAll()
    ]);
    commit("SET_ASSETS_LOADED", false);
  }
};

const mutations = {
  SET_ASSETS_LOADED: (state, bool) => {
    state.assetsLoaded = bool;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
